/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/
.custom-vector-map {
    svg {
        width: 100%;
        max-height: 100%;
        path {
            fill: var(--#{$prefix}light) !important;
        }
    }
}

.flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-card {
    tr,
    td {
        font-size: 14px !important;
    }
}

.keyword-search {
    .form-control {
        height: 38px;
        padding-left: 40px;
        padding-right: 30px;
    }

    span.search-widget-icon {
        position: absolute;
        z-index: 10;
        font-size: 18px;
        line-height: 38px;
        left: 13px;
        top: 0;
        color: var(--#{$prefix}header-item-sub-color);
    }

    .search-widget-icon-close {
        right: 7px;
        left: auto !important;
    }

    ::-webkit-input-placeholder {
        /* Edge */
        color: var(--#{$prefix}header-item-sub-color);
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--#{$prefix}header-item-sub-color);
    }

    ::placeholder {
        color: var(--#{$prefix}header-item-sub-color);
    }

    .keyword-wrapper {
        position: relative;
        &:hover {
            & > .dropdown-submenu {
                display: block;
            }
            .keyword-item {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }
    .keyword-wrapper__child {
        position: relative;
        &:hover {
            & > .dropdown-submenu__child {
                display: block;
            }

            & > .dropdown-submenu {
                display: block;
              }

            // .keyword-item__child {
            //     background-color: $dropdown-link-hover-bg;
            // }
        }
    }

    .dropdown-submenu,
    .dropdown-submenu__child {
        left: 100% !important;
        top: 0% !important;
        transform: none !important;
        display: none;
        & .dropdown-item {
            min-height: 38px;
        }
    }
}

.date-picker-wrapper-custom {
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__input-container .form-control {
        height: 38px;
        border-color: var(--vz-border-color);
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker--time-only {
        .react-datepicker__triangle::after,
        .react-datepicker__triangle::before {
            left: -27px !important;
        }
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
        background-color: #8ec0e7;
    }

    .react-datepicker__week-number--selected {
        border-radius: 8px;
        background-color: #009ef7;
        color: #fff;
      }
    .react-datepicker__week {
        &:has(.react-datepicker__week-number):has(.react-datepicker__day) {
          border: 1px dashed transparent;
          border-radius: 10px;
          transition: all 0.3s ease;
        }
        &:has(.react-datepicker__week-number):has(.react-datepicker__day:hover),
        &:has(.react-datepicker__week-number--selected) {
          background-color: #a7dfff;
          border-color: #009ef7;
        }
    }
}
.form-control.search {
    height: 38px;
    border-color: var(--vz-border-color);
}
.react-datepicker {
    color: var(--vz-vertical-menu-sub-item-color) !important;
    border: 1px solid var(--vz-primary) !important;
    border-radius: 0.4rem !important;
    .react-datepicker__year--container {
        width: 350px;
        .react-datepicker__year-wrapper {
            max-width: 350px;
            .react-datepicker__year-text {
                margin: 10px;
                font-size: 0.875rem;
                font-family: "Open Sans", sans-serif;
            }
        }
    }
    .react-datepicker__month-container {
        width: 290px;
        .react-datepicker__month .react-datepicker__month-text {
            margin: 10px;
            font-size: 0.875rem;
            width: 4.6rem;
            font-family: "Open Sans", sans-serif;
        }
    }
    .react-datepicker__navigation-icon {
        top: 6px;
    }
    .react-datepicker__header {
        background-color: var(--vz-primary);
        border-bottom: 1px solid var(--vz-primary);
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: #fff;
    }

    .react-datepicker__triangle::before,
    .react-datepicker__triangle::after {
        border-top: none;
        border-bottom-color: var(--vz-primary) !important;
    }

    .react-datepicker-popper[data-placement^="bottom"].react-datepicker__triangle::before {
        top: -1px;
        border-bottom-color: var(--vz-primary) !important;
    }

    .react-datepicker__navigation-icon::before {
        border-color: #fff !important;
    }

    .react-datepicker__day {
        color: var(--vz-vertical-menu-sub-item-color) !important;
    }
    .react-datepicker__day--disabled {
        color: #ccc !important;
    }
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__day--keyboard-selected {
        background-color: var(--vz-primary) !important;
        color: #fff !important;
        font-weight: 500 !important;
    }
    .react-datepicker__day-name {
        color: #fff !important;
        font-weight: 500 !important;
    }
    .react-datepicker__time-container {
        border-left: 1px solid var(--vz-primary) !important;
    }
}

/* Base skeleton style */
.skeleton {
    background-color: #eeeeee;  /* Light gray background color */
    border-radius: 4px;      /* Default rounded corners */
    margin-bottom: 0px;     /* Space between each skeleton */
    position: relative;      /* To position the pseudo-element */
    overflow: hidden;        /* Hide any overflow */
  }
  
  /* Create the loading animation effect */
  .skeleton::before {
    content: '';   /* Empty content */
    position: absolute;  /* Absolute positioning for the animation */
    top: 0;
    left: -100%;  /* Start the animation off-screen */
    height: 100%;  /* Full height of the skeleton element */
    width: 100%;   /* Full width of the skeleton element */
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    /* Create a linear gradient effect to simulate the "wave" */
    animation: loading 1.5s infinite;  /* Apply the loading animation */
  }
  
  /* Define the keyframe animation for the loading wave */
  @keyframes loading {
    0% {
      left: -100%;  /* Start off-screen to the left */
    }
    50% {
      left: 100%;   /* Move the wave to the right */
    }
    100% {
      left: 100%;   /* End the animation off-screen on the right */
    }
  }
  

.hashtags-custom {
    div.ReactTags__tags {
        position: relative;
    }

    /* Styles for the input */
    div.ReactTags__tagInput {
        width: 100%;
        display: inline-block;
        margin-bottom: 5px;
    }
    div.ReactTags__tagInput input.ReactTags__tagInputField,
    div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
        // height: 31px;
        // margin: 0;
        // font-size: 12px;
        // width: 100%;
        // border: 1px solid #eee;
        // padding: 0 4px;
    }

    /* Styles for selected tags */
    div.ReactTags__selected span.ReactTags__tag {
        border: 1px solid var(--vz-primary);
        color: var(--vz-white);
        background-color: var(--vz-primary);
        font-size: 85%;
        display: inline-block;
        padding: 1px 3px 1px 3px;
        margin: 0 5px 5px 0px;
        cursor: move;
        border-radius: 4px;
    }
    div.ReactTags__selected button.ReactTags__remove {
        color: var(--vz-white);
        background-color: var(--vz-primary);
        margin-left: 0px;
        cursor: pointer;
        border: none;
        font-size: 15px !important;
        line-height: 17px !important;
    }

    /* Styles for suggestions */
    div.ReactTags__suggestions {
        position: absolute;
        z-index: 3;
        margin-top: 5px;
        border-radius: var(--vz-border-radius);
    }
    div.ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        border-radius: var(--vz-border-radius);
        background: white;
        min-width: 200px;
        padding: 0;
    }
    div.ReactTags__suggestions li {
        border-radius: var(--vz-border-radius);
        overflow: hidden;
        padding: 1px 10px;
        margin: 0;
        cursor: pointer;
        min-width: 200px;
        min-height: 38px;
        line-height: 36px;
    }
    div.ReactTags__suggestions li mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
    }
    div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
        background: rgba(244, 246, 249, 255);
        color: var(--vz-body-color);
        border-color: var(--vz-border-color);
        cursor: pointer;
    }
}

.table-custom-pagination {

    &.scroll-position-top{
        transform:rotateX(180deg);
        & .table{
            transform:rotateX(180deg);
            margin-bottom: 0px;
            margin-top: 10px;
        }
    }
    .infinite-loading-outer {
        position: relative;
        width: 100%;
        height: 0px;
        display: none;
        background: rgba(48, 158, 247, 0.3);
        // transition: all 300 ease-in;
        &.is-show-loading {
            display: block;
        }
    }

    .infinite-loading-inner {
        position: absolute;
        width: 0px;
        height: 4px;
        left: 0px;
        background: rgba(48, 158, 247, 0.7);
        animation: 2s infinite linear loadingAnimation;
    }

    table {
        tr:has(.row-bg-danger){
            background-color: rgba(240, 101, 72, 0.2);
        }
        tr:has(.row-bg-primary){
            background-color: rgba(37, 160, 226, 0.2);
        }
        tr:has(.row-bg-secondary){
            background-color: rgba(135, 138, 153, 0.1);
        }
        th.cell-bg-active{
            color: rgba(240, 101, 72, 1);
            font-weight: 800;
            border-left: 1px solid rgba(240, 101, 72, 1);
            border-right: 1px solid  rgba(240, 101, 72, 1);
            border-top: 1px solid  rgba(240, 101, 72, 1);
            background-color:  rgba(240, 101, 72, 0.2);
        }
        td:has(.cell-bg-active){
            color: rgba(240, 101, 72, 1);
            font-weight: 800;
            border-left: 1px solid rgba(240, 101, 72, 1);
            border-right: 1px solid  rgba(240, 101, 72, 1);
        }
        tr:last-child td:has(.cell-bg-active){
            border-bottom: 1px solid  rgba(240, 101, 72, 1);
        }
    }

    @keyframes loadingAnimation {
        0% {
            left: 0px;
            width: 0px;
        }

        25% {
            left: 0%;
            width: 60%;
        }

        70% {
            left: 60%;
            width: 40%;
        }

        90% {
            left: 100%;
            width: 0px;
        }
    }

    .table-have-first-row-is-total tbody tr:first-child, .table-have-second-row-is-total tbody tr:nth-child(2)  {
        background-color: var(--vz-warning-bg-subtle);
        font-weight: 700;
    }
}

.table-pagination-modal{
    .infinite-loading-outer {
        position: relative;
        width: 100%;
        height: 0px;
        display: none;
        background: rgba(48, 158, 247, 0.3);
        // transition: all 300 ease-in;
        &.is-show-loading {
            display: block;
        }
    }
    
    .infinite-loading-inner {
        position: absolute;
        width: 0px;
        height: 4px;
        left: 0px;
        background: rgba(48, 158, 247, 0.7);
        animation: 2s infinite linear loadingAnimation;
    }
}

.search-filter-keyword {
    .op {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__multi-value .op {
        display: none;
    }
    .name-prefix__multi-value__remove:hover {
        background-color: transparent;
        color: #fff;
    }

    .name-prefix__menu {
        z-index: 3;
    }
}
.search-filter-campaign {
    .op {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__multi-value .op, .name-prefix__single-value .op {
        display: none;
    }
    .name-prefix__multi-value__remove:hover {
        background-color: transparent;
        color: #fff;
    }

    .name-prefix__menu {
        z-index: 3;
    }
}

.search-filter-section-code{
    .op {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__multi-value .op, .name-prefix__single-value .op {
        display: none;
    }
    .name-prefix__multi-value__remove:hover {
        background-color: transparent;
        color: #fff;
    }

    .name-prefix__menu {
        z-index: 3;
    }
}
.search-filter-category-type{
    .name-prefix__menu {
        z-index: 3;
    }
}


.dropdown-status-rounded{
    .name-prefix__control{
        border-radius: var(--vz-border-radius);
    }
    .name-prefix__menu {
        z-index: 3;
    }
    &.z-hight{
        .name-prefix__menu {
            z-index: 11;
        }  
    }
}

.search-filter-classification-type{
    .name-prefix__menu {
        z-index: 10;
    }
}

.search-filter-currencies{
    .op {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__single-value .op {
        display: none;
    }
    .name-prefix__multi-value__remove:hover {
        background-color: transparent;
        color: #fff;
    }
}
.search-template-chart {
    .op {
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__single-value .op {
        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
    .name-prefix__multi-value__remove {
        background-color: transparent;
        color: #fff;
    }
}

.keyword-search {
    .dropdown-menu:has(.result-search-menu) {
        display: block;
    }

    .dropdown-submenu:has(.result-search-sub) {
        display: block;
    }

    .dropdown-submenu__child:has(.result-search-sub__child) {
        display: block;
    }

    .drop {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
        // font-style: italic;
    }

    .dropdown-menu .result-search-menu .dropdown-item:only-child,
    .dropdown-menu .result-search-sub .dropdown-item:only-child,
    .dropdown-menu .result-search-sub__child .dropdown-item:only-child {
        border-radius: var(--vz-dropdown-border-radius);
    }
}

.select-schedule {
    .name-prefix__menu {
        z-index: 4;
    }
}
.date-picker-wrapper-custom {
    .react-datepicker-popper {
        z-index: 3;
        min-width: 400px;
    }
    &.z-hight{
        .react-datepicker-popper {
            z-index: 11;
        }
    }
    .react-datepicker-popper:has(.react-datepicker--time-only) {
        z-index: 3;
        min-width: 85px;
    }
}
.search-filter-media {
    .name-prefix__menu {
        z-index: 10;
    }
    .op {
        color: var(--vz-footer-color);
        font-size: 13px;
        margin-top: 2px;
    }
    .name-prefix__multi-value .op, .name-prefix__single-value .op {
        display: none;
    }
}

.search-filter-topic {
    .name-prefix__menu {
        z-index: 3;
    }
}
.search-filter-type {
    .name-prefix__menu {
        z-index: 3;
    }
}

.select-category-type {
    .name-prefix__menu {
        z-index: 3;
    }
}

.limit-text-to-two-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}

.quill-container-template {
    .ql-container {
        border: 0px solid #eaeaea !important;
        border-radius: 5px;
        &.ql-snow {
            height: 100% !important;
        }
    }
    .ql-toolbar {
        display: none;
    }
    .ql-editor {
        min-height: calc(100dvh - 300px);
        height: 100% !important;
    }
    &.show-border {
        .ql-toolbar {
            border: 1px solid #eaeaea !important;
        }
        .ql-container {
            border: 1px solid #eaeaea !important;
            border-top: none !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .ql-toolbar {
            display: block;
        }
        .ql-editor {
            min-height: calc(100dvh - 200px - 190px - 100px);
        }
        &.height-auto{
            .ql-editor {
                min-height: 300px;
            }
        }
    }
}

.tag-style {
    border: 1px solid var(--vz-primary);
    color: var(--vz-white);
    background-color: var(--vz-primary);
    font-size: 85%;
    display: inline-block;
    padding: 1px 3px 1px 3px;
    margin: 0 5px 5px 0px;
    cursor: move;
    border-radius: 2px;
}

.tagify.form-custom-input-tagify {
    align-items: flex-end;
    padding: 4px 5px;
    min-height: 38px;
    border: var(--vz-border-width) solid var(--vz-input-border-custom);
    & > .tagify__tag {
        border: 1px solid var(--vz-primary);
        color: var(--vz-white);
        background-color: var(--vz-primary);
        font-size: 85%;
        padding: 1px 3px 1px 3px;
        border-radius: 2px;
        height: 24px;
        margin-top: 3px;
        margin-bottom: 1px;
        & > div::before {
            opacity: 0;
            background-color: var(--vz-primary);
        }
        & .tagify__tag-text {
            color: #fff;
            font-size: 13px;
            font-style: normal;
            font-weight: normal;
        }
        &[isfixed=true]{
            background-color: var(--vz-footer-color);
            border: 1px solid var(--vz-footer-color);
        }
        // &[ispicked=true]{
        //     background-color: var(--vz-success);
        //     border: 1px solid var(--vz-success);
        // }
    }
    & .tagify__tag .tagify__tag__removeBtn {
        color: #fff;
        background-color: transparent;
        width: 10px;
        height: 10px;
        margin: 0px;
    }
    & .tagify__input {
        margin: 0px;
        margin-bottom: 0px;
        padding-top: 3px;
        padding-bottom: 3px;

        // var(--vz-border-width) solid var(--vz-input-border-custom)
    }
}

.input-tagify-lang-ko {
    &.hashtags {
        .tagify.form-custom-input-tagify {
            .tagify__input::before {
                content: "해시태그...";
                color: var(--vz-secondary-text-emphasis);
            }
        }
    }
    &.keywords {
        .tagify.form-custom-input-tagify {
            .tagify__input::before {
                content: "키워드...";
                color: var(--vz-secondary-text-emphasis);
            }
        }
    }
}
.input-tagify-lang-en {
    &.hashtags{
        .tagify.form-custom-input-tagify {
            .tagify__input::before {
                content: "Hashtags...";
                color: var(--vz-secondary-text-emphasis);
            }
        }
    }
    &.keywords {
        .tagify.form-custom-input-tagify {
            .tagify__input::before {
                content: "Keywords...";
                color: var(--vz-secondary-text-emphasis);
            }
        }
    }
}

.table-hover-td-content-preview{
    &:hover{
        background-color: var(--vz-table-hover-bg)!important;
    }
}

.box-hover-primary{
    border-color: var(--vz-input-border-custom);
    &:hover{
    border-color: var(--vz-primary);
   }
}

.tox{
    // display: none!important;
    .tox-notifications-container, .tox-notification{
        height: 0!important;
        overflow: hidden;
        display: none!important;
    }

}
.tox-tinymce{
    border: 1px solid #eee!important;
}

// .table-has-checkbox-select{
//     tr:has(input[type="checkbox"]:checked){
//         background-color: var(--vz-primary-bg-subtle);
//     }
// }

.item-keyword-suggestion{
    width: calc(100% / 3);
    @media (max-width: 575.98px) {
        width: calc(100% / 2);
    }
    @media (max-width: 991.98px) {
        width: calc(100% / 3);
    }
    @media (min-width: 1400px){ 
        width: calc(100% / 4);
    }
}

// Chat GPT
.bg-box-chat-gpt{
    background: url("../../../images/chat-bg-pattern.png");
    transition: all 0.4s;
    position: relative;
    background-color: var(--#{$prefix}body-bg);
}

.form-control-popper-date-picker-range{
    .react-datepicker {
        padding-left: 120px;
    }
    .react-datepicker__header {
        border-top-left-radius: 0px;
    }
    .picker-presets{
        display: flex;
        flex-direction: column;
        width: 120px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        ul {
            flex: auto;
            list-style: none;
            overflow: auto;
            margin: 0;
            padding: 8px;
            border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
            li {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-radius: 4px;
                padding-inline: 8px;
                padding-block: 1px;
                cursor: pointer;
                transition: all 0.3s;
                margin-top: 10px;
                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }
            }
            li.preset-active{
                background-color: var(--vz-primary);
                color: #fff;
              }
            li.preset-active ~ li.preset-active{
                  background-color: transparent;
                  color: inherit;
              }
        }
    }
    button.react-datepicker__navigation.react-datepicker__navigation--previous {
        transform: translateX(120px);
    }
}

.btn-hover-blue{
    &:hover{
        & button{
            background: #a6ccf7;
        }
    }
}

.link-text-1-line{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(var(--vz-link-color-rgb))
    
}
.link-text-2-line{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(var(--vz-link-color-rgb))
}

.btn-clearable-value{
    color: hsl(0, 0%, 80%);
    &:hover{
           color: hsl(0, 0%, 60%);
    }
}

.menu-card-statistics{
    max-width: 100%;
    .card-animate{
        width: 200px; 
    }
    &.total-3{
        .card-animate{
            width: 130px; 
        }
    }
    @media (min-width: 992px) {
        max-width: 350px;
        .card-animate{
            width: 150px;
        }
    }
    @media (max-width: 991.98px) {
        max-width: 100%;
        justify-content: space-between;
        .card-animate{
            width: 47%;
        }
        &.total-3{
            .card-animate{
                width: 100%; 
            }
        }
    }
    @media (max-width: 575.98px) {
        .card-animate{
            width: 47%;
        }
        &.total-3{
            .card-animate{
                width: 100%; 
            }
        }
    }
    .card-animate.card-animate-small {
        width: 90px;
        height: 55px;
    }
    @media (max-width: 720px) {
        .card-animate.card-animate-small {
            width: 80px;
            height: 55px;
        }
    }
    
}
.menu-card-statistics-x2{
    .card-animate{
        width: 450px;
    }
    @media (max-width: 991.98px) {
        .card-animate{
            width: 100%;
        }
    }
}

.search-filter-custom-style{
    width: 120px;
    &.w-150px{
        width: 130px;
    }
    & .name-prefix__control{
        border-radius: 20px;
        background-color: #eaeaea!important;
    }
}

.nav-sub-child{
    padding-left: 15px;
    & .nav-link:before {
        background-color: #0000;
        border: 1px solid;
        border-radius: 50%;
        height: 5px!important;
        left: 5px;
        top: 16px;
        width: 5px!important;
    }
}

.nav-sub-none-child{
    & .nav-link{
        color: #121212!important;
    }
    & .nav-link::before{
        background-color: #121212!important;
    }
    & .nav-link:after {
        display: none!important;
    }
    &.active{
        & .nav-link{
           color: var(--vz-vertical-menu-item-active-color)!important; 
        }
    }
}

.clear-css-p-in-box{
    p {
        margin-top: 0px;
        margin-bottom: 0rem;
    }
}

.box-template-form{ 
    background-color: var(--vz-topbar-search-bg);
    border-radius: var(--vz-border-radius-lg);
    .quill-container-template{
        background-color: var(--vz-secondary-bg);
    }
    @media (min-width: 992px) {
        max-height: calc(-150px + 100dvh);
        min-height: calc(-100px + 100dvh);
        overflow-y: auto;
    }
}

.color-scrollbar-os, .table-responsive, body, .modal-body, .hashtags_selected{
    &::-webkit-scrollbar {
        width: 10px; /* Set width of the scrollbar */
        height: 10px;
	    background-color: rgb(245, 245, 245, 0.8);
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(245, 245, 245, 0.8);
        border-radius: 10px;
        padding: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-image: radial-gradient(circle, rgba(215,190,201,0.5) 0%, rgba(148,187,233,1) 100%);;
        border-radius: 6px; /* Add border radius to the thumb */
        margin: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-image: radial-gradient(circle, rgba(215,190,201,0.5) 0%, rgba(148,187,233,1) 100%);;
    }
    @media (max-width: 992px) {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
    }

}

.hidden-scrollbar-os{
    &::-webkit-scrollbar {
        width: 0px; /* Set width of the scrollbar */
        height: 0px;
	    background-color: transparent;
    }
}

.sticky-table-inventory {
    width: 100%;
    table-layout: fixed; 
        & thead th:nth-child(1), tbody td:nth-child(1){
            position: sticky;
            z-index: 2;
            left:0;
        }
        & thead th:nth-child(1), tbody td:nth-child(1){
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                top:0;
                bottom:0;
                left:-1px;
                background-color: var(--vz-border-color);
                z-index: 2;
            }
        }
        @media (min-width: 600px) {
            & thead th:nth-child(2), tbody td:nth-child(2){
                position: sticky;
                z-index: 2;
                left: 60px
            }
            & thead th:nth-child(2){
                &::before{
                    content: "";
                    position: absolute;
                    width: 1.5px;
                    top:0;
                    bottom:0;
                    left:-1.5px;
                    background-color: var(--vz-table-bg);
                    z-index: 2;
                }
            }
            & tbody td:nth-child(2){
                &::before{
                    content: "";
                    position: absolute;
                    width: 1.5px;
                    top:0;
                    bottom:0;
                    left:-1.5px;
                    background-color: var(--vz-card-bg);
                    z-index: 2;
                }
            }
            &.is-scroll{
                & tbody td:nth-child(2),& thead th:nth-child(2){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        left: 230px;
                        bottom: -1px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }                       
        @media (min-width: 992px) {
            & thead th:nth-child(1), tbody td:nth-child(1), thead th:nth-child(2), tbody td:nth-child(2){
                &::before{
                    width: 0px;
                    z-index: 0;
                    display: none;
                }
            }
            & thead th:nth-child(3), tbody td:nth-child(3){
                position: sticky;
                z-index: 2;
                left: 310px
            }
            &.is-scroll{
                & tbody td:nth-child(3),& thead th:nth-child(3){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        bottom: -1px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
        & thead th:nth-child(1),thead th:nth-child(2), thead th:nth-child(3){
            background-color: var(--vz-table-bg);
        }
        & tbody td:nth-child(1),tbody td:nth-child(2),tbody td:nth-child(3){
            background-color: var(--vz-card-bg);
        }
       
}

.sticky-table-report-media {
    width: 100%;
    table-layout: fixed; 
        & thead th:nth-child(1), tbody td:nth-child(1){
            position: sticky;
            z-index: 2;
            left:0;
        }
        & thead th:nth-child(1), tbody td:nth-child(1){
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                top:0;
                bottom:0;
                left:-1px;
                background-color: var(--vz-border-color);
                z-index: 2;
                
            }
        }        
        @media(max-width: 599px){
            &.is-scroll{
                & tbody td:nth-child(1) , & thead th:nth-child(1){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        bottom: -1px;
                        left: 140px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
        @media (min-width: 600px) {
            & thead th:nth-child(1), tbody td:nth-child(1){
                &::before{
                    width: 0px;
                    z-index: 0;
                    display: none;
                }
            }
            & thead th:nth-child(2), tbody td:nth-child(2){
                position: sticky;
                z-index: 2;
                left: 150px
            }
            &.is-scroll{
                & tbody td:nth-child(2) , & thead th:nth-child(2){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        bottom: -1px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
        & thead th:nth-child(1), thead th:nth-child(2){
            background-color: var(--vz-table-bg);
        }
        & tbody td:nth-child(1), tbody td:nth-child(2){
            background-color: var(--vz-card-bg);
        }
        &.table-have-first-row-is-total{
            & tbody tr:first-child{
                & td:nth-child(1), & td:nth-child(2){
                    background-color: var(--vz-warning-bg-subtle);
                }
            }
        }
       
}

.sticky-table-report-agency {
    width: 100%;
    table-layout: fixed; 
        & thead th:nth-child(1), tbody td:nth-child(1){
            position: sticky;
            z-index: 2;
            left:0;
        }
        & thead th:nth-child(1), tbody td:nth-child(1){
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                top:0;
                bottom:0;
                left:-1px;
                background-color: var(--vz-border-color);
                z-index: 2;
                
            }
        }        
        @media(max-width: 599px){
            &.is-scroll{
                & tbody td:nth-child(1) , & thead th:nth-child(1){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        bottom: -1px;
                        left: 140px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
        @media (min-width: 600px) {
            & thead th:nth-child(1), tbody td:nth-child(1){
                &::before{
                    width: 0px;
                    z-index: 0;
                    display: none;
                }
            }
            & thead th:nth-child(2), tbody td:nth-child(2){
                position: sticky;
                z-index: 2;
                left: 150px
            }
            &.is-scroll{
                & tbody td:nth-child(2) , & thead th:nth-child(2){
                    &::before{
                        position: absolute;
                        content: "";
                        width: 9px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        right: 2px;
                        top: -1;
                        bottom: -1px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
        & thead th:nth-child(1), thead th:nth-child(2){
            background-color: var(--vz-table-bg);
        }
        & tbody td:nth-child(1), tbody td:nth-child(2){
            background-color: var(--vz-card-bg);
        }
        &.table-have-first-row-is-total{
            & tbody tr:first-child{
                & td:nth-child(1), & td:nth-child(2){
                    background-color: var(--vz-warning-bg-subtle);
                }
            }
        }
       
}

.sticky-table-news-keyword{
    width: 100%;
    table-layout: fixed; 
        & thead th:nth-child(1), & tbody td:nth-child(1){
            position: sticky;
            z-index: 2;
            left:0;
        }
        & thead th:nth-child(1), & tbody td:nth-child(1){
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                top:0;
                bottom:0;
                left:-1px;
                background-color: var(--vz-border-color);
                z-index: 2;
            }
        }
        @media (min-width: 600px) {
            & thead th:nth-child(2), tbody td:nth-child(2){
                position: sticky;
                z-index: 2;
                left: 50px
            }
            & thead th:nth-child(2){
                &::before{
                    content: "";
                    position: absolute;
                    width: 1.5px;
                    top:0;
                    bottom:0;
                    left:-1.5px;
                    background-color: var(--vz-table-bg);
                    z-index: 2;
                }
            }
            & tbody td:nth-child(2){
                &::before{
                    content: "";
                    position: absolute;
                    width: 1.5px;
                    top:0;
                    bottom:0;
                    left:-1.5px;
                    background-color: var(--vz-card-bg);
                    z-index: 2;
                }
            }
        }
        
        @media (min-width: 992px) {
            & thead th:nth-child(1), tbody td:nth-child(1), thead th:nth-child(2), tbody td:nth-child(2){
                &::before{
                    width: 0px;
                    z-index: 0;
                    display: none;
                }
            }
            & thead th:nth-child(3), tbody td:nth-child(3){
                position: sticky;
                z-index: 2;
                left: 120px
            }
            & thead th:nth-child(4), tbody td:nth-child(4){
                position: sticky;
                z-index: 2;
                left: 270px
            }
        }

        & thead th:nth-child(1), thead th:nth-child(2), thead th:nth-child(3), thead th:nth-child(4){
            background-color: var(--vz-table-bg);
        }
        & tbody td:nth-child(1), tbody td:nth-child(2), tbody td:nth-child(3), tbody td:nth-child(4){
            background-color: var(--vz-card-bg);
        }
        &.is-scroll{
            & tbody td:nth-child(4),  & thead th:nth-child(4){
                &::before{
                    position: absolute;
                    content: "";
                    width:5px;
                    height: calc(100% + 2px);
                    background-color: transparent;
                    z-index: 5;
                    right: 2px;
                    top: -1;
                    bottom: -1px;
                    box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                }
            }
        }
}

.sticky-table-index-log {
    @media (min-width: 650px) {
        width: 100%;
        table-layout: fixed; 
            & thead th:nth-child(2):not(.none-sticky), tbody td:nth-child(2){
                position: sticky;
                z-index: 2;
                left:0;
            }
            & thead th:nth-child(2):not(.none-sticky){
                background-color: var(--vz-table-bg);
            }
            & tbody td:nth-child(2){
                background-color: var(--vz-card-bg);
            }
            &.table-have-first-row-is-total tbody tr:first-child td:nth-child(2){
                background-color: var(--vz-warning-bg-subtle);
            }
            
            &.is-scroll{
                & tbody td:nth-child(2),  & thead th:nth-child(2):not(.none-sticky){
                    &::before{
                        position: absolute;
                        content: "";
                        width:5px;
                        height: calc(100% + 2px);
                        background-color: transparent;
                        z-index: 5;
                        left: 145px;
                        top: -1;
                        bottom: -1px;
                        box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                    }
                }
            }
        }
}
.sticky-table-ranking-monitoring{
    width: 100%;
        table-layout: fixed; 
    @media (min-width: 1300px) {
        & thead th:nth-child(1):not(.none-sticky), tbody td:nth-child(1),
        thead th:nth-child(2):not(.none-sticky), tbody td:nth-child(2),
        thead th:nth-child(3):not(.none-sticky), tbody td:nth-child(3),
        thead th:nth-child(4):not(.none-sticky), tbody td:nth-child(4){
           &::before{
               width: 0px;
               z-index: 0;
               display: none;
           }
       }
       & thead th:nth-child(1):not(.none-sticky), tbody td:nth-child(1){
           position: sticky;
           z-index: 9;
           left: 0px
       }
       & thead th:nth-child(2):not(.none-sticky), tbody td:nth-child(2){
           position: sticky;
           z-index: 9;
           left: 50px
       }
       & thead th:nth-child(3):not(.none-sticky), tbody td:nth-child(3){
           position: sticky;
           z-index: 9;
           left: 140px;
       }
       & thead th:nth-child(4):not(.none-sticky), tbody td:nth-child(4){
           position: sticky;
           z-index: 9;
           left: 260px;
       }
       & thead th:nth-child(5):not(.none-sticky), tbody td:nth-child(5){
           position: sticky;
           z-index: 9;
           left: 350px;
       }

        & thead th:nth-child(1), thead th:nth-child(2), thead th:nth-child(3), thead th:nth-child(4), thead th:nth-child(5){
            background-color: var(--vz-table-bg);
            &:has(.row-bg-danger){
                background-color: #fce2de;
            }
            &:has(.row-bg-primary){
                background-color: #d4edf9;
            }
        }
        & tbody td:nth-child(1), tbody td:nth-child(2), tbody td:nth-child(3), tbody td:nth-child(4), tbody td:nth-child(5){
            background-color: var(--vz-card-bg);
            &:has(.row-bg-danger){
                background-color: #fce2de;
            }
            &:has(.row-bg-primary){
                background-color: #d4edf9;
            }
        }
        &.is-scroll{
            & tbody td:nth-child(5),  & thead th:nth-child(5):not(.none-sticky){
                &::before{
                    position: absolute;
                    content: "";
                    width:5px;
                    height: calc(100% + 2px);
                    background-color: transparent;
                    z-index: 9;
                    right: 2px;
                    top: -1;
                    bottom: -1px;
                    box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                }
            }
        } 
    }
}

.sticky-table-chart-ranking-singer{
    width: 100%;
    table-layout: fixed; 
    @media (min-width: 700px) {
        & thead th:nth-child(1):not(.none-sticky), tbody td:nth-child(1){
           &::before{
               width: 0px;
               z-index: 0;
               display: none;
           }
       }
       & thead th:nth-child(4):not(.none-sticky), tbody td:nth-child(4){
           position: sticky;
           z-index: 9;
           left: 0px
       }

        & thead th:nth-child(4), thead th:nth-child(4){
            background-color: var(--vz-table-bg);
            &:has(.row-bg-danger){
                background-color: #fce2de;
            }
            &:has(.row-bg-primary){
                background-color: #d4edf9;
            }
        }
        & tbody td:nth-child(4), tbody td:nth-child(4){
            background-color: var(--vz-card-bg);
            &:has(.row-bg-danger){
                background-color: #fce2de;
            }
            &:has(.row-bg-primary){
                background-color: #d4edf9;
            }
        }
        &.is-scroll{
            & tbody td:nth-child(4),  & thead th:nth-child(4):not(.none-sticky){
                &::before{
                    position: absolute;
                    content: "";
                    width:5px;
                    height: calc(100% + 2px);
                    background-color: transparent;
                    z-index: 9;
                    right: 2px;
                    top: -1;
                    bottom: -1px;
                    box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
                }
            }
        } 
    }
}

.table-bordered-dashed{
    tbody td, thead th, thead tr{
        border-bottom-style: dashed !important;
    }
}

.copy-text-to-clipboard-wrapper{
    .icon-copy{
        opacity: 0;
        transition: all 0.5s ease;
    }
    &:hover > .icon-copy{
        opacity: 1;
    }
}

.header-item {
    border-radius: var(--vz-border-radius);
}


@media (min-width: 1300px) {
    .modal-xxll {
      --vz-modal-width: 1300px;
    }
}
@media (min-width: 1400px) {
    .modal-xxll {
      --vz-modal-width: 1400px;
    }
}
@media (min-width: 1500px) {
    .modal-xxll {
      --vz-modal-width: 1500px;
    }
}



.nestable {
    position: relative;
  }
  .nestable .nestable-list {
    margin: 0;
    padding: 0 0 0 40px;
    list-style-type: none;
    max-width: 700px;
    & .nestable-item-name{
        max-width: 700px;
        width: 100%;
    }
  }
  .nestable > .nestable-list {
    padding: 0;
  }
  .nestable-item,
  .nestable-item-copy {
    margin: 10px 0 0;
  }
  .nestable-item:first-child,
  .nestable-item-copy:first-child {
    margin-top: 0;
  }
  .nestable-item .nestable-list,
  .nestable-item-copy .nestable-list {
    margin-top: 10px;
  }
  .nestable-item {
    position: relative;
  }
  .nestable-item.is-dragging .nestable-list {
    pointer-events: none;
  }
  .nestable-item.is-dragging * {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  .nestable-item.is-dragging:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #87ceeb;
    border: 1px dashed #4682b4;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  .nestable-item-icon {
    margin-right: 5px;
    cursor: pointer;
  }
  .nestable-drag-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
  }
  .nestable-drag-layer > .nestable-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
  }
  
.nestable-item-name{
    .box-edit-node-tree{
        opacity: 0;
        transition: all 300 ease-in;
    }
    &:hover{
        .box-edit-node-tree{
            opacity: 1;
        }
    }
}

.tree-category{
    .box-edit-node-tree{
        opacity: 0;
        transition: all 300 ease-in;
    }
    .rst__row:hover{
        .box-edit-node-tree{
            opacity: 1;
        }
    }
    .rst__rowContents {
        align-items: center;
    }
    span.rst__rowTitle.rst__rowTitleWithSubtitle {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .rst__rowLabel {
        padding-right: 0px;
    }

    .rst__rowContents {
        border-radius: 0px 8px 8px 0px;
    }
    .rst__moveHandle{
        border-radius: 8px 0px 0px 8px;
        border-right: 1px dashed #bfbfbf;
    }
}

.off-canvas-lg{
    &.off-canvas-lg-500{
        width: 500px !important;
    }
    &.off-canvas-lg-600{
        width: 600px !important;
    }
    &.off-canvas-lg-700{
        width: 650px !important;
    }
   
}

@media (max-width: 600px) {
    .box-apex-charts{
        width: 100%;
        height: 100%;
        overflow-x: auto;
        overflow-y:hidden;
        margin-bottom: 10px;
        & .apex-charts{
            width: 700px;
        }
    }
}

td.bg-secondary-100{
    background-color: #f8f9fb!important;
}
.table-fixed {
    table-layout: fixed;
}
.text-overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
}

.sticky-menu-tab-top-table{
    @media (min-width: 1000px) {
        position: sticky;
        top: 70px;
        background-color: var(--vz-secondary-bg);
        z-index: 10;
    }
}

.badge-tooltip-auto{
    position: relative;
    &::after{
        content: 'Auto';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #d5d5d5;
        color: #7f7d7d;
        transform: translate(10px, -75%);
        padding: 1px 4px 2px 4px;
        border-radius: 8px;
        font-size: 10px;
        line-height: 10px;
    }
    &.auto_flg_en::after{
        content: 'Auto';
    }
    &.auto_flg_ko::after{
        content: '자동';
    }
}
.custom-tooltip{
    .tooltip.show{
        opacity: 1!important;
    }
    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
        border-left-color: #eaeaea;
    }
    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
        border-left-color: #eaeaea;
    }
}

.sm-border-left{
    @media (min-width: 500px) {
        border-left: 1px solid #eaeaea;
    }
    
}